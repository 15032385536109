<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box :back="true" back-component-name="management.supplier-company.index">
        <template v-slot:title>
          <span>{{  }}</span>
        </template>

        <template v-slot:preview>
          <template>
            <text-input
                :title="`${$t('roles_scope.role_name')}`"
                :model.sync="role.name"
                :is-valid="role.name != null && role.name.length !== 0 ? true : null"
                :error=" role.name != null && role.name.length === 0 ? $t('general.this_field_cant_be_empty') : null"
            ></text-input>

            <div class="row">
              <div class="col-md-6" v-for="(scope_group,group_name) in scopes" :key="scope_group">
                <div class="example mb-15">
                  <div class="example-preview">
                    <i class="flaticon2-shield text-success"></i>
                    <span>{{$t('scope.' + group_name)}}</span>
                  </div>
                  <div class="example-code">
                    <div class="example-highlight row">
                      <div class="col-md-12">
                          <b-form-group>
                            <b-form-checkbox-group id="checkbox-group-2" v-model="selectedScope" name="scopes" inline >
                              <b-form-checkbox :value="scope.id"  v-for="(scope,index) in scope_group" :key="index">{{$t('scope.'+scope.action)}}</b-form-checkbox>
                            </b-form-checkbox-group>
                          </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button @click="$router.push({name: 'management.roles-scope.index'})" type="button" class="btn btn-pill btn-outline-danger">{{ $t("general.cancel") }}</button>
              <button :disabled="role.name == null || role.name.length === 0" @click="createOrUpdateUnit"  class="btn btn-pill btn-primary ml-3">
                {{ $t(id != null ? 'general.save' : 'general.create') }}
              </button>
            </div>
          </template>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from 'vuex'
import {
  CREATE_ITEM,
  CURRENT_ITEM,
  ERROR,
  GET_ITEM_DETAIL_BY_ID,
  LOADING, SET_CURRENT_ITEM, SUCCESS, UPDATE_ITEM_BY_ID
} from "@/core/services/store/panel-managment/crud/panel.management.module";
import TextInput from "@/assets/components/inputs/TextInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import {LANGUAGE} from "@/core/services/store/system-options.module";
import {GET_ITEMS as REST_GET_ITEM} from "@/core/services/store/REST.module";

export default {
  name: "RolesScopeCreateEdit",
  components: {
    TextInput,
    DashboardBox,
    SelectInput,
    MultipleSelectInput,
    CustomMultiSelect
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
      role: CURRENT_ITEM,
      language: LANGUAGE,
    }),
  },
  data() {
    return {
      id: null,
      selectedRoles: '0',
      selectedScope: [],
      storeRoles: [],
      storeScope: [],
      scopes : [],
      roles: null,
    };
  },
  methods: {
    ...mapMutations({
      setScope: SET_CURRENT_ITEM,
    }),
    createOrUpdateUnit() {
      let payload = {
        url: this.id == null ? 'api/roles' : 'api/roles/' + this.id,
        id: this.id,
        contents: {
          name: this.role.name
        },
        //redirectRouteName: 'management.roles-scope.index',
        successMessage: this.$t(this.id == null ? 'general.successfully_created' : 'general.successfully_updated'),
      }
      this.$store.dispatch( this.id == null ? CREATE_ITEM : UPDATE_ITEM_BY_ID , payload).then((result) => {
        if (result.status){
          let role_id = result.data.data.id;
          let payload = {
            url : 'api/roles/' + role_id + '/scopes',
            id: this.id,
            contents: {
              scope_id: this.selectedScope,
              role_id : role_id
            },
            redirectRouteName: 'management.roles-scope.index',
            successMessage: this.$t(this.id == null ? 'general.successfully_created' : 'general.successfully_updated'),
          }
          this.$store.dispatch( CREATE_ITEM, payload)
        }
      });
    },
  },
  created() {
    this.setScope({
      id: null,
       model: [],
    });
  },
  mounted() {
    this.id = this.$route.params.id;
    if(this.id != null){
      let payload = {
        url: 'api/roles/' + this.id,
        id: this.id,
      }
      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload)

      this.$store.dispatch(REST_GET_ITEM, {
        url : 'api/roles/' + this.id + '/scopes',
        acceptPromise: true
      }).then((result) => {
        let temp = result.data
        let scopes = [];
        for (let i = 0; i < temp.length; i++) {
          scopes.push(temp[i].scope_id);
        }
        //this.selectedScope = this.convertArrayToObjectByKey(temp,'scope_id');
        this.selectedScope = scopes;
      });

    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t(this.id ? "roles_scope.edit_roles_scope" : "roles_scope.create_roles_scope")},
    ]);
    let self = this;

    this.$store.dispatch(REST_GET_ITEM, {
      url: 'api/scopes',
      acceptPromise: true,
      filters: {}
    }).then((result) => {
      if (result.status) {
        this.scopes = result.data;
        let temp = result.data;
        let returnValues = {};
        for (let i = 0; i < temp.length; i++) {
          {
            returnValues[temp[i].id] = temp[i].model;
          }
        }
        self.storeScope = returnValues;
      }
    })
  },
}
</script>

<style scoped>
#dashboard-box{
  padding: 10px;
}
.example-code{
  position: inherit !important;
  left: 0 !important;
  right: 0 !important;
  opacity: 1 !important;
}
.example .example-preview{
  padding: 10px 5px;
}
.example-preview i {
  padding-left: 5px;
}
.example-preview span{
  padding-left: 10px;
  font-size: 16px;
}
</style>
